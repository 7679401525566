<template>
  <div>
    <h1 v-if="!serviceId"><icon class="mr-1" type="cog" size="0.9" /> {{ $t('createNewType', {type: $t('type.service')}) }}</h1>
    <h1 v-else><icon class="mr-1" type="cog" size="0.9" /> {{ $t('updateType', {type: $t('type.service')}) }}</h1>
    <input
        type="text"
        :class="['form-text mt-4', $v.name.$dirty && $v.name.$error ? 'form-group--error' : '']"
        :id="'name'"
        v-model="name"
        :placeholder="$t('enternamehere')"
        @input="delayTouch($v.name)"
    >
    <div class="lighter mb-1">{{ $t('NameIsDefinitive') }}</div>
    <div class="form-error" v-if="$v.name.$dirty && !$v.name.required && $v.name.$error">{{ $t('errors.required') }}</div>
    <div class="form-error" v-if="$v.name.$dirty && !$v.name.alphaNumSpace">{{ $t('errors.alphaNumSpaceOnly') }}</div>

    <div class="form-error" v-if="$v.name.$dirty && !$v.name.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>

    <div class="form-error" v-if="$v.name.$dirty && !$v.name.minLength">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>
    <div class="form-error" v-if="$v.name.$dirty && !$v.name.isUnique">{{ $t('errors.sfxdataNameAlreadyExists') }}</div>
    <div class="form-error" v-if="error">{{ $t(error) }}</div>

    <input
        type="text"
        :class="['form-text mt-4', $v.displayName.$dirty && $v.displayName.$error ? 'form-group--error' : '']"
        :id="'displayName'"
        v-model="displayName"
        :placeholder="$t('displayName')"
        @input="delayTouch($v.displayName)"
    >
    <div class="form-error" v-if="$v.displayName.$dirty && !$v.displayName.required && $v.displayName.$error">{{ $t('errors.required') }}</div>

    <div class="form-error" v-if="$v.displayName.$dirty && !$v.displayName.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>

    <div class="form-error" v-if="$v.displayName.$dirty && !$v.displayName.minLength">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>

    <div class="inline-edit-label">
      <b>{{ $t('description') }}</b>
    </div>

    <textarea
        :class="['form-text', $v.description.$dirty && $v.description.$error ? 'form-group--error' : '']"
        :id="'description'"
        v-model="description"
        :placeholder="$t('enterdeschere')"
        @input="delayTouch($v.description)"
    />
    <div class="form-error" v-if=" $v.description.$dirty && !$v.description.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>
    <div class="w-100 mt-5" />
    <Button
        class="mb-3"
        :deactivated="!enableSaving"
        @click="() => { if(serviceId) update(); else save(); }">
      <icon v-if="enableSaving" type="save"/><icon v-else type="ban" /> {{ $t(!serviceId ? 'SAVE' : 'SAVE') }}
    </Button>
  </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import {alphaNumSpace} from '@/customValidators';
import Button from "@/components/forms/Button";
import Icon from "@/components/Icon";
import { mapState } from 'vuex';
import DateMixin from "@/components/mixins/DateMixin.js.vue";
import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";

export default {
  name: "ServiceAddForm",
  components: {
    Button,
    Icon,
  },
  mixins: [DateMixin, VuelidateMixin],
  props: {
    organizationId: {type: String, default: ''},
    /**
     * @param serviceId add-form switches into edit mode of service ID given
     * */
    serviceId: {type: String, default: ''}
  },
  data() {
    return {
      description: '',
      name: '',
      displayName: '',
      type: 'service',
      enableSaving: false,
      error: '',
      localOrganizationId: this.organizationId,
      service: '',
      hasLoaded: false,
      loading: false
    };
  },
  computed: mapState({currentUserOrg: state => state.users.currentUserOrg,}),
  watch: {
    name() {
      this.error = null;
    },
    '$v.$invalid': function() {
      this.checkSaving();
    },
    organizationId(val) {
      if(val) {
        this.localOrganizationId = this.organizationId;
      }
    },
    currentUserOrg(val) {
      if(!this.organizationId) {
        this.localOrganizationId = val;
      }
    },
    serviceId(val) {
      if(val) {
        this.loadServiceData();
      }
    },
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(127),
      alphaNumSpace,
      async isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === '') {
          return true;
        }
        let bool = true;
        await this.$store.dispatch('checkIfServiceNameExists', {
          type: 'Asset',
          name: value,
        }).then(data => {
          bool = (data.length === 0 || (data[0].id === this.serviceId));
        });
        return bool;
      },
    },
    displayName: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(127),
    },
    description: {
      maxLength: maxLength(1024),
    },
  },
  beforeMount() {
    if(this.serviceId) {
      this.loadServiceData();
    }
  },
  methods: {
    loadServiceData() {
      this.$store.dispatch('loadService', {
        id: this.serviceId,
      }).then(data => {
        if(data) {
          this.name = data.name;
          this.displayName = data.displayName;
          this.description = data.description;
        }
        this.hasLoaded = true;
      });
    },
    checkSaving() {
      this.enableSaving = this.name && !this.$v.$invalid;
    },
    getArgs() {
      return {
        name: this.name,
        displayName: this.displayName,
        description: this.description,
      };
    },
    update: async function() {
      let args = this.getArgs();
      args.id = this.serviceId;
      await this.$store.dispatch('updateService', args).then(() => {
        this.$emit('finished');
      }).catch(e => {
        console.log(e);
        this.error = e;
      });
    },
    save: async function () {
      let args = this.getArgs();
      args.organizationId = this.localOrganizationId;
      await this.$store.dispatch('createService', args).then(data => {
        this.$router.push(`/admin/services/${data.id}/general`)
      }).catch(e => {
        console.log(e);
        this.error = e;
      });
    },
  }
}
</script>